.gift-slider {


  &__title {
    text-align: center;
    font-weight: 300;
    margin-bottom: 40px;
  }

  &__descr {
    color: #fff;
    background-color: $color-gold;
    padding: 9px 60px;
    display: inline-block;
    position: absolute;
    top: 25px;
    right: 0;
    border-top-left-radius: 13px;
    border-bottom-left-radius: 13px;
    line-height: 100%;
    font-weight: 500;
  }

  &__image {
    display: block;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
  }

  &__link {
    text-align: center;
    display: block;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.05em;
    color: #000000;
    margin-bottom: 10px;
  }

  &__num {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.05em;
  }
}

.gift-sliderBlock {
  padding: 50px 0;
  background-color: #f0f0f0;
}