@mixin tabs {
  .tabs__nav-btn--active {
    // color: #fff;
    // background-color: $color-gold;
    font-weight: 700;
  }

  .tabs__panel {
    display: none;
  }

  .tabs__panel--active {
    display: block;
  }
}