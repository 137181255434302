.preview {
  padding-top: 90px;

  &__card-list {
    display: flex;
    flex-wrap: wrap;

    @media screen and(max-width:968px) {
      justify-content: center;
    }
  }

  &__card {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }

  &__card:not(:last-child) {
    margin-right: 20px;

    @include mobile {
      margin-right: 0;
    }
  }

  &__card-title {
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.05em;
    margin-bottom: 15px;
    text-align: center;
    margin-bottom: 20px;
  }

  &__card-text {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-align: center;
    margin-bottom: 20px;
    max-width: 380px;
    margin: 0 auto;
  }

  &__card-link {
    display: block;
    font-size: 16px;
    line-height: 18px;
    font-weight: 300;
    letter-spacing: 0.05em;
    color: #000000;
    text-decoration: underline;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  &__card-image {
    border-radius: 10px;
    width: 380px;
    height: 255px;
    object-fit: cover;
    display: block;
    margin-top: auto;

    @include mobile {
      width: 100%;
    }
  }
}