.burger {
  @include burger;
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 11;

  @media screen and (max-width:968px) {
    display: block;
  }

  &--active {
    color: #fff;
  }
}

.logo {
  display: block;
  width: 165px;
  height: 100px;

  @media screen and (max-width:500px) {
    width: 80px;
    height: 50px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@media screen and (max-width:968px) {
  .nav {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-1000px);
    transition: all 0.3s ease-in-out;
    z-index: 10;
  }

  .header__right {
    padding-right: 80px;
  }

}

@media screen and (max-width:500px) {
  .header__right {
    padding-right: 50px;
  }
}

.nav.menu--active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.nav__list {
  display: flex;
  align-items: center;

  @media screen and (max-width:968px) {
    display: block;
    padding: 50px 15px;
  }
}

.nav__item:not(:last-child) {
  margin-right: 50px;

  @include tablet {
    margin-right: 20px;
  }

  @media screen and (max-width:968px) {
    margin-bottom: 50px;
  }
}

.nav__link {
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #000000;
  text-transform: uppercase;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: $color-gold;
  }

  @media screen and (max-width:968px) {
    color: #fff;
    font-weight: 700;
    font-size: 24px;
  }
}

.header {
  padding: 20px 0;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__right-phone {
    display: block;
    font-size: 17px;
    line-height: 18px;
    font-weight: 700;
    letter-spacing: 0.05em;
    position: relative;
    color: #000;
    padding-left: 25px;
    margin-bottom: 30px;

    @media screen and (max-width:500px) {
      font-size: 14px;
    }

    &::before {
      content: "";
      position: absolute;
      width: 14px;
      height: 25px;
      display: block;
      background-image: url(../img/phone-icon.png);
      background-repeat: no-repeat;
      background-size: contain;
      top: 0;
      left: 0;
    }
  }

  &__right-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__right-login {
    background-image: url(../img/login-icon.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
    margin-right: 40px;
  }

  &__right-cart {
    background-image: url(../img/cart-icon.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 28px;
    height: 24px;
    margin-right: 15px;
    position: relative;
  }

  &__right-cart span {
    // font-family: Tahoma;
    position: absolute;
    bottom: 0;
    right: -15px;
    font-size: 19px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0.025em;
    color: #000000;
  }
}

.header-bottom {
  height: 30px;
  text-align: center;
  background-color: $color-gold;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #ffffff;
  text-transform: uppercase;
  padding-top: 6px;
}