.feedback {
  padding-top: 100px;

  &__title {
    font-weight: 300;
    text-align: center;
    margin-bottom: 70px;
  }

  &__stars {
    display: block;
    width: 97px;
    height: 16px;
    background-image: url(../img/stars.png);
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 20px;
  }

  &__text {
    line-height: 20px;
    letter-spacing: 0.05em;
    text-align: center;
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: auto;
  }

  &__item {
    max-width: 300px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__author {
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-align: center;
    margin-top: auto;
  }

  &-slider {
    max-width: 1180px;
  }
}

.feedback {
  position: relative;

  .swiper-button-prev,
  .swiper-button-next {
    top: 50%;
    width: 55px;
    height: 55px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .swiper-button-prev::after,
  .swiper-button-next:after {
    display: none;
  }

  .swiper-button-next {
    background-image: url(../img/btn-next-gold.png);

    @media screen and(max-width:1050px) {}
  }

  .swiper-button-prev {
    background-image: url(../img/btn-prev-gold.png);

    @media screen and(max-width:1050px) {}
  }
}