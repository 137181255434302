.gift-tabs {

  &__inner {
    width: 100%;
  }

  &__nav {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    row-gap: 10px;
    column-gap: 20px;

    @media (max-width: (1250px)) {
      grid-template-columns: repeat(4, 1fr);

    }

    @media (max-width: (850px)) {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 10px;
    }

    @include mobile {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: (400px)) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 5px;
    }
  }


  &__nav-btn {
    height: 32px;
    width: 180px;
    border: 1px solid $color-gold;
    border-radius: 5px;
    background-color: #fff;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: #383838;
    padding: 5px 10px;

    @media (max-width: (400px)) {
      width: 140px;
      font-size: 14px;
      padding: 5px;
    }
  }

}

.gift-tabs {
  &__title {
    margin-top: 0;
    margin-bottom: 10px;
    text-align: center;
    font-weight: 300;
  }

  &__subtitle {
    text-align: center;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.05em;
    margin-bottom: 20px;
  }

  &__wrapper {
    width: 100%;
    background-color: #f0f0f0;
    padding: 10px 0;
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    row-gap: 70px;
    padding-top: 70px;
    padding-bottom: 40px;

    @media (max-width: (992px)) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 40px;
    }

    @include mobile {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 20px;
    }
  }

  &__card-img {
    display: block;
    width: 100%;
    min-height: 250px;
    margin-bottom: 30px;
    border-radius: 20px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__card-link {
    display: block;
    text-align: center;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.05em;
    color: #000000;
    margin-bottom: 10px;
  }

  &__card-text {
    text-align: center;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.05em;
  }
}

.mixitup-control-active {
  color: #fff;
  background-color: $color-gold;
}