.breadcrumbs {
  padding: 30px 0;

  &__list {
    display: flex;

    @include mobile {
      flex-wrap: wrap;
    }
  }

  &__item:not(:last-child) {
    padding-left: 5px;
    margin-right: 5px;
    position: relative;

    &:after {
      content: "/";

    }
  }

  &__link {
    font-size: 13px;
    line-height: 25px;
    font-weight: 400;
    letter-spacing: 0.01em;
    color: #383838;
    font-weight: 500;
  }

  &__item-current {
    a {
      font-weight: 700;
    }
  }

  &--dark {
    background-color: #f2f2f2;
  }
}