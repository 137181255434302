.slider1 {
  height: 420px;
  margin: 0 auto;
  max-width: 3200px;

  @include big-desktop {
    height: 300px;
  }

  @media screen and(max-width: 968px) {
    height: 250px;
  }

  &__cover-title {
    font-size: 51px;
    font-weight: 400;
    letter-spacing: 0.05em;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
    position: absolute;
    top: 90px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    width: 100%;

    @media screen and(max-width: 968px) {
      top: 50px;
      font-size: 36px;
    }
  }

  &__cover-btn {
    max-width: 300px;
    margin: 0 auto;
    position: absolute;
    z-index: 5;
    top: 155px;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);

    @media screen and(max-width: 968px) {
      top: 110px;
      font-size: 18px;
    }
  }
}

.slider1 .swiper-slide {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  // height: 420px;
}

.slider1 {
  position: relative;
}

.slider1 .swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #fff;
  opacity: 1;
  cursor: pointer;
}

.slider1 .swiper-pagination-bullet-active {
  background-color: #fff;
}