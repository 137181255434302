.quiz-top {
  padding-top: 30px;
  padding-bottom: 5px;
  border-bottom: 1px solid $color-gold;

  &__title {
    text-align: center;
    margin: 0;
    padding: 0;
    font-size: 48px;
    line-height: 48px;
    font-weight: 300;
    letter-spacing: 0.01em;
    color: #383838;

    @include mobile {
      font-size: 32px;
      line-height: 32px;
    }
  }
}

.quiz {
  &-form {
    width: 100%;
  }

  .options {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width:1200px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .choose__left {
    @media screen and (max-width:1200px) {
      margin-bottom: 30px;
    }
  }

  &-choose__descr {
    font-size: 20px;
    line-height: 22px;
    font-weight: 300;
    letter-spacing: 0.05em;
    color: #000000;
    padding-left: 35px;
    position: relative;
    display: inline-block;

    &:before {
      content: "";
      display: block;
      width: 26px;
      height: 26px;
      border-radius: 3px;
      background-color: #fff;
      border: 1px solid $color-grey;
      position: absolute;
      top: 0;
      left: 0;
    }

    &::after {
      content: "";
      display: block;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background-color: $color-grey;
      position: absolute;
      top: 7px;
      left: 7px;
    }
  }

  &__text {
    font-size: 21px;
    line-height: 25px;
    font-weight: 300;
    letter-spacing: 0.01em;
    color: #000000;
    margin-bottom: 40px;
  }

  &-form__btn {
    margin: 0 auto;
  }
}

.quiz__step {
  margin: 0 auto;
  padding: 0;
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: #000000;
  position: relative;
  padding-left: 50px;
  display: inline-block;

  @include mobile {
    text-align: left;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    color: #fff;
    background-color: $color-gold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-family: Ubuntu;
    font-size: 21px;
    line-height: 21px;
    font-weight: 700;
    letter-spacing: 0.01em;
  }
}

.quiz1 {

  background-color: #f4f4f4;
  padding: 70px 0 40px;
  text-align: center;

  @include mobile {
    text-align: left;
  }

  &__step {
    &::before {
      content: "1";
    }
  }

  .choose__subtitle {
    font-weight: 300;
  }

  .choose__left {
    margin-right: 10px;
  }

  &-choose__images {
    display: flex;
    max-width: 620px;
    height: 220px;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    border: 2px solid $color-grey;

    @include small-tablet {
      flex-direction: column;
      height: auto;
    }
  }

  &-options__input {
    display: none;

    &:checked+.quiz1-options__label {
      .quiz1-choose__images {
        border: 2px solid $color-gold;
      }

      .quiz-choose__descr {
        font-weight: 700;

        &::before {
          border: 1px solid $color-gold;
          background-color: $color-gold;
        }

        &:after {
          background-color: #fff;
        }
      }
    }
  }

}

.quiz2 {
  background-color: #cccccc;
  padding: 70px 0 40px;

  &__container {
    text-align: center;

    @include mobile {
      text-align: left;
    }
  }

  &__step {
    margin-bottom: 65px;

    @include mobile {
      text-align: left;
    }

    &::before {
      content: "2";
    }
  }

  &-options__text {
    font-size: 21px;
    line-height: 37px;
    font-weight: 300;
    letter-spacing: 0.01em;
    color: #000000;
    position: relative;
    padding-left: 35px;
    display: inline-block;
    margin-bottom: 30px;

    &:before {
      content: "";
      display: block;
      width: 26px;
      height: 26px;
      border-radius: 3px;
      background-color: #fff;
      border: 1px solid $color-grey;
      position: absolute;
      top: 6px;
      left: 0;
    }

    &::after {
      content: "";
      display: block;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background-color: $color-grey;
      position: absolute;
      top: 13px;
      left: 7px;
    }
  }

  &-options {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;

    @media screen and (max-width:1200px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include small-tablet {
      grid-template-columns: repeat(3, 1fr);
    }

    @include mobile {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &-options__img {
    display: block;
    width: 180px;
    height: 180px;
    border: 2px solid transparent;
    border-radius: 10px;
    object-fit: contain;

    @include mobile {
      width: 130px;
      height: 130px;
    }
  }

  &-options__input {
    display: none;

    &:checked+.quiz2-options__label {
      .quiz2-options__img {
        border: 2px solid $color-gold;
      }

      .quiz2-options__text {
        font-weight: 700;

        &::before {
          border: 1px solid $color-gold;
          background-color: $color-gold;
        }

        &:after {
          background-color: #fff;
        }
      }

    }
  }
}

.quiz3 {
  background-color: #e0e0e0;
  padding: 70px 0 140px;
  text-align: center;

  @include mobile {
    text-align: left;
  }

  @include small-tablet {
    padding: 70px 0 40px;
  }

  &__step {
    @include mobile {
      text-align: left;
    }

    &:before {
      content: "3";
    }
  }

  &__wrapper {
    overflow: auto;
  }

  &__inner {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    -webkit-column-gap: 8px;
    -moz-column-gap: 8px;
    column-gap: 9px;
    row-gap: 1px;
    margin: 0 auto;
    background-image: url(../img/quiz/quiz-bg.png);
    width: 1180px;
    height: 367px;
    padding: 28px 24px;
    margin-bottom: 30px;
  }

  &__btn {
    width: 95px;
    height: 95px;
    border: 4px solid transparent;
    border-radius: 10px;

    img {
      width: 100%;
      object-fit: contain;
    }

    &:hover {
      border: 4px solid $color-gold;
    }
  }


}

.graph-modal__container.graph-modal__container--quiz {
  padding: 0;
  border-radius: 0;
}

.graph-modal__container .graph-modal__close {
  top: 20px;
  right: 20px;
}

.quiz-modal {
  &__top {
    background-color: #f4f4f4;
    padding: 35px;

    @include mobile {
      padding: 35px 10px;
    }
  }

  &__top-title {
    font-size: 25px;
    line-height: 30px;
    font-weight: 400;
    letter-spacing: 0.01em;
    color: #000000;
    text-align: center;
    margin-bottom: 25px;
  }

  &__content {
    display: flex;
    justify-content: center;

    @include small-tablet {
      flex-direction: column;
      align-items: center;
    }
  }

  &__img {
    display: block;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    margin-right: 20px;
    box-shadow: 5px 7px 10px 1px rgba(25, 25, 25, 0.56);
    object-fit: cover;

    @include small-tablet {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  &__input {
    width: 100%;
    height: 42px;
    border: 1px solid $color-grey;
    border-radius: 5px;
    padding: 10px 20px;
    margin-bottom: 5px;

    &::placeholder {
      font-size: 20px;
      line-height: 37px;
      font-weight: 300;
      color: #808080;
    }
  }

  &__text {
    text-align: center;
    font-size: 15px;
    line-height: 30px;

    letter-spacing: 0.01em;
    color: #000000;
    font-weight: 400;
    margin-bottom: 35px;

    span {
      font-weight: 700;
    }
  }

  &__counter {
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 37px;
    font-weight: 300;
    color: #000000;
    margin-bottom: 40px;

  }

  &__btn--minus {
    width: 36px;
    height: 36px;
    border: 1px solid $color-grey;
    border-radius: 3px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    cursor: pointer;
  }

  &__btn--plus {
    width: 36px;
    height: 36px;
    border: 1px solid $color-grey;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    cursor: pointer;
  }

  &__input--counter {
    font-weight: 700;
    border: none;
    background-color: transparent;
    max-width: 55px;
    padding: 10px;
  }

  &__submit {
    width: 100%;
  }

  &__bottom {
    background-color: #e0e0e0;
    padding: 35px;
    text-align: center;

    @include mobile {
      padding: 35px 10px;
    }
  }

  &__bottom-title {
    margin-bottom: 15px;
    font-size: 25px;
    line-height: 37px;
    font-weight: 500;
    letter-spacing: 0.01em;
    color: #000000;
    text-align: center;
  }

  &__bottom-inner {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-bottom: 20px;

    @include small-tablet {
      grid-template-columns: repeat(3, 1fr);
    }

    @include mobile {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__item {
    border-radius: 50%;
    box-shadow: 5px 7px 10px 1px rgba(25, 25, 25, 0.56);
    width: 135px;
    height: 135px;

    @include mobile {
      width: 120px;
      height: 120px;
    }
  }

  &__option-img {
    border-radius: 50%;
  }

  &__option-input {
    display: none;

    &:checked+.quiz-modal__option-label {
      .quiz-modal__option-img {
        border: 4px solid $color-gold;
      }
    }
  }

  &__bottom-btn {
    text-align: center;
    font-size: 20px;
    line-height: 37px;
    font-weight: 400;
    letter-spacing: 0.01em;
    color: #000000;
    border-bottom: 1px dashed #000;
  }
}