.choose {
  padding-top: 90px;

  &__title {
    font-weight: 300;
    text-align: center;
    margin-bottom: 30px;
  }

  &__subtitle {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-align: center;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 70px;

    @media screen and(max-width:1150px) {
      justify-content: center;
    }
  }

  &__img {
    display: block;

  }

  &__img:not(:last-child) {
    margin-right: 10px;
  }

  &__images {
    display: flex;
    margin-bottom: 20px;

    @include mobile {
      display: block;
    }
  }

  &__descr {
    display: block;
    text-align: center;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.05em;
    margin-bottom: 10px;
    color: #000;

    span {
      font-size: 16px;
      display: block;
      margin: 0 auto;
    }
  }

  &__btn {
    margin: 0 auto;
  }
}