.gift {
  padding-top: 95px;

  &__title {
    margin-bottom: 20px;
    font-weight: 300;
    text-align: center;

    @include small-tablet {
      font-size: 26px;
    }
  }

  &__subtitle {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-align: center;
    margin-bottom: 55px;
  }

  &__image {
    position: relative;
    width: 100%;
  }

  &__descr {
    color: #fff;
    background-color: $color-gold;
    padding: 5px 30px 7px 30px;
    display: inline-block;
    position: absolute;
    bottom: 25px;
    right: 0;
    border-top-left-radius: 13px;
    border-bottom-left-radius: 13px;
    line-height: 100%;
  }
}

.gift-slider {
  max-width: 2400px;
  position: relative;

  .swiper-button-prev,
  .swiper-button-next {
    top: 50%;
    width: 55px;
    height: 55px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .swiper-button-prev::after,
  .swiper-button-next:after {
    display: none;
  }

  .swiper-button-next {
    right: 50%;
    transform: translateX(500px);
    background-image: url(../img/btn-next.png);

    @media screen and(max-width:1050px) {
      right: 10px;
      transform: translateX(0);
    }
  }

  .swiper-button-prev {
    left: 50%;
    transform: translateX(-500px);
    background-image: url(../img/btn-prev.png);

    @media screen and(max-width:1050px) {
      left: 10px;
      transform: translateX(0);
    }
  }
}

.exclusives-slider {
  position: relative;

  .swiper-button-prev,
  .swiper-button-next {
    top: 40%;
    width: 55px;
    height: 55px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .swiper-button-prev::after,
  .swiper-button-next:after {
    display: none;
  }

  .swiper-button-next {
    right: 50%;
    transform: translateX(500px);
    background-image: url(../img/btn-next.png);

    @media screen and(max-width:1050px) {
      right: 10px;
      transform: translateX(0);
    }
  }

  .swiper-button-prev {
    left: 50%;
    transform: translateX(-500px);
    background-image: url(../img/btn-prev.png);

    @media screen and(max-width:1050px) {
      left: 10px;
      transform: translateX(0);
    }
  }
}

.gift-slider {
  max-width: 2400px;
  position: relative;

  .swiper-button-prev,
  .swiper-button-next {
    top: 50%;
    width: 55px;
    height: 55px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .swiper-button-prev::after,
  .swiper-button-next:after {
    display: none;
  }

  .swiper-button-next {
    right: 50%;
    transform: translateX(500px);
    background-image: url(../img/btn-next.png);

    @media screen and(max-width:1050px) {
      right: 10px;
      transform: translateX(0);
    }
  }

  .swiper-button-prev {
    left: 50%;
    transform: translateX(-500px);
    background-image: url(../img/btn-prev.png);

    @media screen and(max-width:1050px) {
      left: 10px;
      transform: translateX(0);
    }
  }
}