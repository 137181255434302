.give {
  padding-top: 100px;

  &__title {
    font-weight: 300;
    text-align: center;
    margin-bottom: 10px;

    @include small-tablet {
      font-size: 26px;
    }
  }

  &__subtitle {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-align: center;
    margin-bottom: 30px;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    flex-wrap: wrap;
  }

  &__card {
    width: 16%;
    min-height: 270px;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 20px;
    border-radius: 20px;
    overflow: hidden;

    @media screen and(max-width:1220px) {
      width: 33%;
      min-height: 450px;
    }

    @include small-tablet {
      min-height: 300px;

    }

    @include mobile {
      min-height: 200px;

    }

  }

  &__card--big {
    width: 50%;
    display: flex;
    align-items: center;
    padding-left: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    overflow: hidden;
    background-position: right;

    @media screen and(max-width:1220px) {
      width: 100%;
      min-height: 450px;
      margin-bottom: 10px;
    }

    @include small-tablet {
      min-height: 300px;

    }

    @include mobile {
      min-height: 200px;
      font-size: 30px;
      line-height: 32px;
    }
  }

  &__card-text--big {
    font-size: 45px;
    line-height: 48px;
    font-weight: 500;
    letter-spacing: 0.025em;
    color: #ffffff;
    max-width: 130px;

    @include mobile {
      font-size: 30px;
      line-height: 32px;
    }
  }

  &__card-text {
    height: 34px;
    width: 100%;
    background-color: $color-gold;
    font-size: 31px;
    font-weight: 500;
    letter-spacing: 0.025em;
    color: #ffffff;
    text-align: center;

    @include mobile {
      font-size: 24px;
    }
  }

  &__btn {
    margin: 0 auto;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    text-decoration: underline;
    display: block;
  }
}