.gallery {
  padding: 150px 0 50px;

  @include big-desktop {
    padding: 50px 0;
  }

  &__title {
    text-align: center;
    font-weight: 300;
    margin-bottom: 20px;
  }

  &__subtitle {
    text-align: center;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.05em;
    margin-bottom: 20px;
  }

  &__text {
    max-width: 775px;
    line-height: 20px;
    letter-spacing: 0.05em;
    align-items: center;
    margin: 0 auto;
    text-align: center;
  }
}

.gallery-slider {
  max-width: 3590px;
  margin-bottom: 45px;
}

.gallery {
  position: relative;

  .swiper-button-prev,
  .swiper-button-next {
    top: 50%;
    width: 55px;
    height: 55px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .swiper-button-prev::after,
  .swiper-button-next:after {
    display: none;
  }

  .swiper-button-next {
    right: 50%;
    transform: translateX(500px);
    background-image: url(../img/btn-next.png);

    @media screen and(max-width:1050px) {
      right: 10px;
      transform: translateX(0);
    }
  }

  .swiper-button-prev {
    left: 50%;
    transform: translateX(-500px);
    background-image: url(../img/btn-prev.png);

    @media screen and(max-width:1050px) {
      left: 10px;
      transform: translateX(0);
    }
  }
}