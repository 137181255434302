.footer {
  background-color: #d6d6d6;
  padding: 45px 0 100px;

  @include small-tablet {
    padding: 45px 0 0 0;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__column {
    @include small-tablet {
      margin-bottom: 30px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__item:not(:last-child) {
    margin-bottom: 30px;
  }

  &__link {
    font-weight: 300;
    letter-spacing: 0.05em;
    color: #080609;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: $color-gold;
      ;
    }
  }

  &__phone {
    display: block;
    font-weight: 500;
    letter-spacing: 0.05em;
    color: #080609;
    margin-bottom: 20px;
  }

  &__mail {
    font-weight: 300;
    letter-spacing: 0.05em;
    color: #080609;
  }

  &__form-title {
    line-height: 21px;
    font-weight: 300;
    letter-spacing: 0.05em;
    color: #080609;
    max-width: 250px;
    margin-bottom: 35px;
  }

  &__form__label {
    display: block;
    width: 265px;
    height: 40px;
    border: 1px solid #080609;
    border-radius: 5px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: -8px;
      right: -8px;
      width: 55px;
      height: 55px;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(../img/form-arr.png);
    }
  }

  &__form-input {
    border: none;
    background-color: transparent;
    width: 100%;
    height: 100%;
    padding: 5px 55px 5px 20px;
  }

  &__column-last {
    padding-left: 15px;
    border-left: 2px solid #080609;

    @media screen and (max-width: 968px) {
      width: 100%;
      border: none;
      padding: 0;
      padding-top: 30px;
    }
  }

  &__column-social {
    @include small-tablet {
      width: 100%;
      padding-top: 30px;
    }
  }
}

.social {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.social__item:not(:last-child) {
  margin-right: 15px;
}

.social__link {
  display: block;
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
  background-size: cover;
}

.social__link--fb {
  background-image: url(../img/fb.png);
}

.social__link--in {
  background-image: url(../img/in.png);
}

.social__link--youtube {
  background-image: url(../img/youtube.png);
}