.shopping {
  padding-bottom: 90px;

  @include small-tablet {
    padding-bottom: 50px;
  }

  &__title {
    text-align: center;
    font-weight: 300;
    margin-bottom: 55px;
  }

  &__title--first {
    margin-bottom: 0;

    @include small-tablet {
      margin-bottom: 20px;
    }
  }

  &__subtitle {
    font-size: 31px;
    font-weight: 500;
    letter-spacing: 0.05em;
    text-align: center;
    margin-bottom: 20px;

    @include small-tablet {
      font-size: 24px;
    }
  }

  &__person-text {
    font-size: 19px;
    line-height: 1.2;
    letter-spacing: 0.005em;
    margin-bottom: 40px;

    @include small-tablet {
      font-size: 16px;
    }

    span {
      font-weight: 700;
    }
  }

  &__person {
    display: flex;
    align-items: center;

    @include small-tablet {
      display: block;
    }
  }

  &__person-img {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
    flex-shrink: 0;

    @include small-tablet {
      display: block;
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }

  &__gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @media (max-width: 992px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mobile {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__gallery-img {
    border-radius: 20px;
    overflow: hidden;
    min-height: 250px;
    margin-bottom: 25px;

    img {
      width: 100%;
      background-color: $color-grey;
      height: 100%;
      object-fit: cover;
    }
  }

  &__gallery-text {
    text-align: center;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.05em;
  }
}