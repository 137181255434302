.banner {

  max-width: 1890px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 90px 0;
  height: 650px;
  margin: 0 auto;
  margin-top: 100px;

  @media screen and(max-width: 1050px) {
    padding: 40px 0;
    height: auto;
  }

  @include small-tablet {
    padding: 10px 0;
    margin-top: 40px;
  }

  &__title {
    max-width: 994px;
    min-height: 157px;
    background-color: rgba(#fff, 0.5);
    padding: 20px 200px;
    font-family: Ubuntu;
    font-size: 51px;
    line-height: 59px;
    letter-spacing: 0.05em;
    margin: 0 auto;
    margin-bottom: 20px;
    border-radius: 20px;
    text-align: center;

    span {
      font-size: 19px;
      line-height: 24px;
      font-weight: 500;
      letter-spacing: 0.05em;
      max-width: 510px;
      display: block;
      margin: 0 auto;

      @include mobile {
        font-size: 12px;
      }
    }

    @media screen and(max-width: 1050px) {
      font-size: 36px;
      max-width: 500px;
      padding: 20px 20px;
      line-height: 100%;
      height: 115px;
    }

    @include small-tablet {
      font-size: 26px;
      max-width: 100%;
      padding: 10px 20px;
      line-height: 100%;
      height: auto;
    }

    @include mobile {
      font-size: 16px;
    }
  }

  &__link {
    font-size: 24px;
    line-height: 29px;
    font-weight: 500;
    letter-spacing: 0.05em;
    color: #ffffff;
    background-color: $color-gold;
    margin: 0 auto;
    display: block;
    padding: 15px 30px;
    max-width: 300px;
    border-radius: 10px;
    text-align: center;

    @media screen and(max-width: 1050px) {
      font-size: 18px;
      padding: 10px 20px;
      max-width: 250px;
    }

    @include small-tablet {
      font-size: 14px;
      padding: 5px;
      line-height: 100%;
      max-width: 200px;
    }
  }
}

.banner__title1 {
  padding: 20px;
  margin-top: 40px;

  @media screen and(max-width: 1050px) {
    padding: 10px;
    max-width: 100%;
  }

}