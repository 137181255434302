.modal-form__btn {
  margin-top: 20px;
}

.graph-modal__container.graph-modal__container--first {
  padding: 30px;
}

.graph-modal__container--first .graph-modal__close {
  top: 20px;
  right: 20px;
  opacity: 1;
}