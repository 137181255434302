/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
@font-face {
  font-family: "Ubuntu";
  src: url("../fonts/../fonts/Ubuntu-Light.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Ubuntu";
  src: url("../fonts/../fonts/Ubuntu-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Ubuntu";
  src: url("../fonts/../fonts/Ubuntu-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Ubuntu";
  src: url("../fonts/../fonts/Ubuntu-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: "Ubuntu", sans-serif;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
  font-weight: 300;
  color: #000;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 15px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important;
  /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1212px;
}

.container-wide {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1930px;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.title {
  font-size: 48px;
  letter-spacing: 0.05em;
  color: #000000;
}
@media (max-width: 768px) {
  .title {
    font-size: 26px;
  }
}

.btn {
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #c4a633;
  padding: 12px 30px;
  font-size: 24px;
  line-height: 29px;
  font-weight: 500;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.btn:hover {
  filter: brightness(1.2);
}

.burger {
  --burger-width: 30px;
  --burger-height: 30px;
  --burger-line-height: 2px;
  position: relative;
  border: none;
  padding: 0;
  width: var(--burger-width);
  height: var(--burger-height);
  color: #000;
  background-color: transparent;
  cursor: pointer;
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 11;
}
.burger::before, .burger::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: var(--burger-line-height);
  background-color: currentColor;
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}
.burger::before {
  top: 0;
}
.burger::after {
  top: calc(100% - var(--burger-line-height));
}
.burger__line {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: var(--burger-line-height);
  background-color: currentColor;
  transform: translateY(-50%);
  transition: transform 0.3s ease-in-out;
}
.burger--active::before {
  top: 50%;
  transform: rotate(45deg);
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}
.burger--active::after {
  top: 50%;
  transform: rotate(-45deg);
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}
.burger--active .burger__line {
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
}
@media screen and (max-width: 968px) {
  .burger {
    display: block;
  }
}
.burger--active {
  color: #fff;
}

.logo {
  display: block;
  width: 165px;
  height: 100px;
}
@media screen and (max-width: 500px) {
  .logo {
    width: 80px;
    height: 50px;
  }
}
.logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 968px) {
  .nav {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-1000px);
    transition: all 0.3s ease-in-out;
    z-index: 10;
  }

  .header__right {
    padding-right: 80px;
  }
}
@media screen and (max-width: 500px) {
  .header__right {
    padding-right: 50px;
  }
}
.nav.menu--active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.nav__list {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 968px) {
  .nav__list {
    display: block;
    padding: 50px 15px;
  }
}

.nav__item:not(:last-child) {
  margin-right: 50px;
}
@media (max-width: 1024px) {
  .nav__item:not(:last-child) {
    margin-right: 20px;
  }
}
@media screen and (max-width: 968px) {
  .nav__item:not(:last-child) {
    margin-bottom: 50px;
  }
}

.nav__link {
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #000000;
  text-transform: uppercase;
  transition: color 0.3s ease-in-out;
}
.nav__link:hover {
  color: #c4a633;
}
@media screen and (max-width: 968px) {
  .nav__link {
    color: #fff;
    font-weight: 700;
    font-size: 24px;
  }
}

.header {
  padding: 20px 0;
}
.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__right-phone {
  display: block;
  font-size: 17px;
  line-height: 18px;
  font-weight: 700;
  letter-spacing: 0.05em;
  position: relative;
  color: #000;
  padding-left: 25px;
  margin-bottom: 30px;
}
@media screen and (max-width: 500px) {
  .header__right-phone {
    font-size: 14px;
  }
}
.header__right-phone::before {
  content: "";
  position: absolute;
  width: 14px;
  height: 25px;
  display: block;
  background-image: url(../img/phone-icon.png);
  background-repeat: no-repeat;
  background-size: contain;
  top: 0;
  left: 0;
}
.header__right-bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header__right-login {
  background-image: url(../img/login-icon.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  margin-right: 40px;
}
.header__right-cart {
  background-image: url(../img/cart-icon.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 28px;
  height: 24px;
  margin-right: 15px;
  position: relative;
}
.header__right-cart span {
  position: absolute;
  bottom: 0;
  right: -15px;
  font-size: 19px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.025em;
  color: #000000;
}

.header-bottom {
  height: 30px;
  text-align: center;
  background-color: #c4a633;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #ffffff;
  text-transform: uppercase;
  padding-top: 6px;
}

.slider1 {
  height: 420px;
  margin: 0 auto;
  max-width: 3200px;
}
@media (max-width: 1440px) {
  .slider1 {
    height: 300px;
  }
}
@media screen and (max-width: 968px) {
  .slider1 {
    height: 250px;
  }
}
.slider1__cover-title {
  font-size: 51px;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
  position: absolute;
  top: 90px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  width: 100%;
}
@media screen and (max-width: 968px) {
  .slider1__cover-title {
    top: 50px;
    font-size: 36px;
  }
}
.slider1__cover-btn {
  max-width: 300px;
  margin: 0 auto;
  position: absolute;
  z-index: 5;
  top: 155px;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}
@media screen and (max-width: 968px) {
  .slider1__cover-btn {
    top: 110px;
    font-size: 18px;
  }
}

.slider1 .swiper-slide {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
}

.slider1 {
  position: relative;
}

.slider1 .swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #fff;
  opacity: 1;
  cursor: pointer;
}

.slider1 .swiper-pagination-bullet-active {
  background-color: #fff;
}

.preview {
  padding-top: 90px;
}
.preview__card-list {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 968px) {
  .preview__card-list {
    justify-content: center;
  }
}
.preview__card {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.preview__card:not(:last-child) {
  margin-right: 20px;
}
@media (max-width: 576px) {
  .preview__card:not(:last-child) {
    margin-right: 0;
  }
}
.preview__card-title {
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.05em;
  margin-bottom: 15px;
  text-align: center;
  margin-bottom: 20px;
}
.preview__card-text {
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-align: center;
  margin-bottom: 20px;
  max-width: 380px;
  margin: 0 auto;
}
.preview__card-link {
  display: block;
  font-size: 16px;
  line-height: 18px;
  font-weight: 300;
  letter-spacing: 0.05em;
  color: #000000;
  text-decoration: underline;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
}
.preview__card-image {
  border-radius: 10px;
  width: 380px;
  height: 255px;
  object-fit: cover;
  display: block;
  margin-top: auto;
}
@media (max-width: 576px) {
  .preview__card-image {
    width: 100%;
  }
}

.gift {
  padding-top: 95px;
}
.gift__title {
  margin-bottom: 20px;
  font-weight: 300;
  text-align: center;
}
@media (max-width: 768px) {
  .gift__title {
    font-size: 26px;
  }
}
.gift__subtitle {
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-align: center;
  margin-bottom: 55px;
}
.gift__image {
  position: relative;
  width: 100%;
}
.gift__descr {
  color: #fff;
  background-color: #c4a633;
  padding: 5px 30px 7px 30px;
  display: inline-block;
  position: absolute;
  bottom: 25px;
  right: 0;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
  line-height: 100%;
}

.gift-slider {
  max-width: 2400px;
  position: relative;
}
.gift-slider .swiper-button-prev,
.gift-slider .swiper-button-next {
  top: 50%;
  width: 55px;
  height: 55px;
  background-repeat: no-repeat;
  background-size: contain;
}
.gift-slider .swiper-button-prev::after,
.gift-slider .swiper-button-next:after {
  display: none;
}
.gift-slider .swiper-button-next {
  right: 50%;
  transform: translateX(500px);
  background-image: url(../img/btn-next.png);
}
@media screen and (max-width: 1050px) {
  .gift-slider .swiper-button-next {
    right: 10px;
    transform: translateX(0);
  }
}
.gift-slider .swiper-button-prev {
  left: 50%;
  transform: translateX(-500px);
  background-image: url(../img/btn-prev.png);
}
@media screen and (max-width: 1050px) {
  .gift-slider .swiper-button-prev {
    left: 10px;
    transform: translateX(0);
  }
}

.exclusives-slider {
  position: relative;
}
.exclusives-slider .swiper-button-prev,
.exclusives-slider .swiper-button-next {
  top: 40%;
  width: 55px;
  height: 55px;
  background-repeat: no-repeat;
  background-size: contain;
}
.exclusives-slider .swiper-button-prev::after,
.exclusives-slider .swiper-button-next:after {
  display: none;
}
.exclusives-slider .swiper-button-next {
  right: 50%;
  transform: translateX(500px);
  background-image: url(../img/btn-next.png);
}
@media screen and (max-width: 1050px) {
  .exclusives-slider .swiper-button-next {
    right: 10px;
    transform: translateX(0);
  }
}
.exclusives-slider .swiper-button-prev {
  left: 50%;
  transform: translateX(-500px);
  background-image: url(../img/btn-prev.png);
}
@media screen and (max-width: 1050px) {
  .exclusives-slider .swiper-button-prev {
    left: 10px;
    transform: translateX(0);
  }
}

.gift-slider {
  max-width: 2400px;
  position: relative;
}
.gift-slider .swiper-button-prev,
.gift-slider .swiper-button-next {
  top: 50%;
  width: 55px;
  height: 55px;
  background-repeat: no-repeat;
  background-size: contain;
}
.gift-slider .swiper-button-prev::after,
.gift-slider .swiper-button-next:after {
  display: none;
}
.gift-slider .swiper-button-next {
  right: 50%;
  transform: translateX(500px);
  background-image: url(../img/btn-next.png);
}
@media screen and (max-width: 1050px) {
  .gift-slider .swiper-button-next {
    right: 10px;
    transform: translateX(0);
  }
}
.gift-slider .swiper-button-prev {
  left: 50%;
  transform: translateX(-500px);
  background-image: url(../img/btn-prev.png);
}
@media screen and (max-width: 1050px) {
  .gift-slider .swiper-button-prev {
    left: 10px;
    transform: translateX(0);
  }
}

.grid {
  padding-top: 100px;
}
@media (max-width: 768px) {
  .grid {
    padding-top: 50px;
  }
}
.grid__title {
  margin-bottom: 20px;
  text-align: center;
  font-weight: 300;
}
@media (max-width: 768px) {
  .grid__title {
    font-size: 26px;
  }
}
.grid__subtitle {
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.05em;
  margin-bottom: 50px;
  text-align: center;
}
.grid__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
@media screen and (max-width: 1050px) {
  .grid__list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .grid__list {
    grid-template-columns: repeat(1, 1fr);
  }
}
.grid__card-image {
  width: 100%;
  object-fit: contain;
  display: block;
  margin-bottom: 30px;
  height: 250px;
}
.grid__card-title {
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.05em;
  text-align: center;
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
  color: #000;
}
.grid__card-descr {
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.05em;
  margin-bottom: 20px;
}
.grid__card-link {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  text-decoration: underline;
  color: #c4a633;
  text-align: center;
  display: block;
  margin: 0 auto;
}

.banner {
  max-width: 1890px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 90px 0;
  height: 650px;
  margin: 0 auto;
  margin-top: 100px;
}
@media screen and (max-width: 1050px) {
  .banner {
    padding: 40px 0;
    height: auto;
  }
}
@media (max-width: 768px) {
  .banner {
    padding: 10px 0;
    margin-top: 40px;
  }
}
.banner__title {
  max-width: 994px;
  min-height: 157px;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 20px 200px;
  font-family: Ubuntu;
  font-size: 51px;
  line-height: 59px;
  letter-spacing: 0.05em;
  margin: 0 auto;
  margin-bottom: 20px;
  border-radius: 20px;
  text-align: center;
}
.banner__title span {
  font-size: 19px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.05em;
  max-width: 510px;
  display: block;
  margin: 0 auto;
}
@media (max-width: 576px) {
  .banner__title span {
    font-size: 12px;
  }
}
@media screen and (max-width: 1050px) {
  .banner__title {
    font-size: 36px;
    max-width: 500px;
    padding: 20px 20px;
    line-height: 100%;
    height: 115px;
  }
}
@media (max-width: 768px) {
  .banner__title {
    font-size: 26px;
    max-width: 100%;
    padding: 10px 20px;
    line-height: 100%;
    height: auto;
  }
}
@media (max-width: 576px) {
  .banner__title {
    font-size: 16px;
  }
}
.banner__link {
  font-size: 24px;
  line-height: 29px;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: #ffffff;
  background-color: #c4a633;
  margin: 0 auto;
  display: block;
  padding: 15px 30px;
  max-width: 300px;
  border-radius: 10px;
  text-align: center;
}
@media screen and (max-width: 1050px) {
  .banner__link {
    font-size: 18px;
    padding: 10px 20px;
    max-width: 250px;
  }
}
@media (max-width: 768px) {
  .banner__link {
    font-size: 14px;
    padding: 5px;
    line-height: 100%;
    max-width: 200px;
  }
}

.banner__title1 {
  padding: 20px;
  margin-top: 40px;
}
@media screen and (max-width: 1050px) {
  .banner__title1 {
    padding: 10px;
    max-width: 100%;
  }
}

.give {
  padding-top: 100px;
}
.give__title {
  font-weight: 300;
  text-align: center;
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .give__title {
    font-size: 26px;
  }
}
.give__subtitle {
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-align: center;
  margin-bottom: 30px;
}
.give__inner {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  flex-wrap: wrap;
}
.give__card {
  width: 16%;
  min-height: 270px;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 20px;
  border-radius: 20px;
  overflow: hidden;
}
@media screen and (max-width: 1220px) {
  .give__card {
    width: 33%;
    min-height: 450px;
  }
}
@media (max-width: 768px) {
  .give__card {
    min-height: 300px;
  }
}
@media (max-width: 576px) {
  .give__card {
    min-height: 200px;
  }
}
.give__card--big {
  width: 50%;
  display: flex;
  align-items: center;
  padding-left: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  overflow: hidden;
  background-position: right;
}
@media screen and (max-width: 1220px) {
  .give__card--big {
    width: 100%;
    min-height: 450px;
    margin-bottom: 10px;
  }
}
@media (max-width: 768px) {
  .give__card--big {
    min-height: 300px;
  }
}
@media (max-width: 576px) {
  .give__card--big {
    min-height: 200px;
    font-size: 30px;
    line-height: 32px;
  }
}
.give__card-text--big {
  font-size: 45px;
  line-height: 48px;
  font-weight: 500;
  letter-spacing: 0.025em;
  color: #ffffff;
  max-width: 130px;
}
@media (max-width: 576px) {
  .give__card-text--big {
    font-size: 30px;
    line-height: 32px;
  }
}
.give__card-text {
  height: 34px;
  width: 100%;
  background-color: #c4a633;
  font-size: 31px;
  font-weight: 500;
  letter-spacing: 0.025em;
  color: #ffffff;
  text-align: center;
}
@media (max-width: 576px) {
  .give__card-text {
    font-size: 24px;
  }
}
.give__btn {
  margin: 0 auto;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  text-decoration: underline;
  display: block;
}

.choose {
  padding-top: 90px;
}
.choose__title {
  font-weight: 300;
  text-align: center;
  margin-bottom: 30px;
}
.choose__subtitle {
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-align: center;
}
.choose__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 70px;
}
@media screen and (max-width: 1150px) {
  .choose__wrapper {
    justify-content: center;
  }
}
.choose__img {
  display: block;
}
.choose__img:not(:last-child) {
  margin-right: 10px;
}
.choose__images {
  display: flex;
  margin-bottom: 20px;
}
@media (max-width: 576px) {
  .choose__images {
    display: block;
  }
}
.choose__descr {
  display: block;
  text-align: center;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
  color: #000;
}
.choose__descr span {
  font-size: 16px;
  display: block;
  margin: 0 auto;
}
.choose__btn {
  margin: 0 auto;
}

.feedback {
  padding-top: 100px;
}
.feedback__title {
  font-weight: 300;
  text-align: center;
  margin-bottom: 70px;
}
.feedback__stars {
  display: block;
  width: 97px;
  height: 16px;
  background-image: url(../img/stars.png);
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 20px;
}
.feedback__text {
  line-height: 20px;
  letter-spacing: 0.05em;
  text-align: center;
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: auto;
}
.feedback__item {
  max-width: 300px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.feedback__author {
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-align: center;
  margin-top: auto;
}
.feedback-slider {
  max-width: 1180px;
}

.feedback {
  position: relative;
}
.feedback .swiper-button-prev,
.feedback .swiper-button-next {
  top: 50%;
  width: 55px;
  height: 55px;
  background-repeat: no-repeat;
  background-size: contain;
}
.feedback .swiper-button-prev::after,
.feedback .swiper-button-next:after {
  display: none;
}
.feedback .swiper-button-next {
  background-image: url(../img/btn-next-gold.png);
}
.feedback .swiper-button-prev {
  background-image: url(../img/btn-prev-gold.png);
}
.gallery {
  padding: 150px 0 50px;
}
@media (max-width: 1440px) {
  .gallery {
    padding: 50px 0;
  }
}
.gallery__title {
  text-align: center;
  font-weight: 300;
  margin-bottom: 20px;
}
.gallery__subtitle {
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.05em;
  margin-bottom: 20px;
}
.gallery__text {
  max-width: 775px;
  line-height: 20px;
  letter-spacing: 0.05em;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}

.gallery-slider {
  max-width: 3590px;
  margin-bottom: 45px;
}

.gallery {
  position: relative;
}
.gallery .swiper-button-prev,
.gallery .swiper-button-next {
  top: 50%;
  width: 55px;
  height: 55px;
  background-repeat: no-repeat;
  background-size: contain;
}
.gallery .swiper-button-prev::after,
.gallery .swiper-button-next:after {
  display: none;
}
.gallery .swiper-button-next {
  right: 50%;
  transform: translateX(500px);
  background-image: url(../img/btn-next.png);
}
@media screen and (max-width: 1050px) {
  .gallery .swiper-button-next {
    right: 10px;
    transform: translateX(0);
  }
}
.gallery .swiper-button-prev {
  left: 50%;
  transform: translateX(-500px);
  background-image: url(../img/btn-prev.png);
}
@media screen and (max-width: 1050px) {
  .gallery .swiper-button-prev {
    left: 10px;
    transform: translateX(0);
  }
}

.breadcrumbs {
  padding: 30px 0;
}
.breadcrumbs__list {
  display: flex;
}
@media (max-width: 576px) {
  .breadcrumbs__list {
    flex-wrap: wrap;
  }
}
.breadcrumbs__item:not(:last-child) {
  padding-left: 5px;
  margin-right: 5px;
  position: relative;
}
.breadcrumbs__item:not(:last-child):after {
  content: "/";
}
.breadcrumbs__link {
  font-size: 13px;
  line-height: 25px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: #383838;
  font-weight: 500;
}
.breadcrumbs__item-current a {
  font-weight: 700;
}
.breadcrumbs--dark {
  background-color: #f2f2f2;
}

.shopping-form {
  padding-top: 15px;
  padding-bottom: 100px;
}
.shopping-form__title {
  text-align: center;
  font-weight: 300;
  margin-bottom: 30px;
}
.shopping-form__item {
  min-height: 220px;
  border: 1px solid #919395;
  border-radius: 20px;
  padding: 30px;
  overflow: hidden;
}
.shopping-form__item--counter {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.shopping-form__subtitle {
  font-size: 43.3px;
  font-weight: 400;
  color: #c4a633;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .shopping-form__subtitle {
    font-size: 26px;
  }
}
.shopping-form__item--sendershort {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.shopping-form__item--sender {
  margin-bottom: 20px;
}
.shopping-form__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 880px;
  width: 100%;
}
@media (max-width: 992px) {
  .shopping-form__inner {
    display: block;
  }
}
.shopping-form__label {
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0.005em;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.shopping-form__label span {
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.005em;
  color: #919395;
  max-width: 250px;
  margin-top: 10px;
}
.shopping-form__label:not(:last-child) {
  margin-right: 20px;
}
.shopping-form__input {
  height: 43px;
  border: 1px solid #919395;
  border-radius: 5px;
  padding: 10px 20px;
}
.shopping-form__input::placeholder {
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0.005em;
}
.shopping-form__label--phone {
  margin-top: 40px;
}
.shopping-form__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
@media (max-width: 992px) {
  .shopping-form__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .shopping-form__grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
.shopping-form__label--long {
  grid-column: span 2;
}
@media (max-width: 768px) {
  .shopping-form__label--long {
    grid-column: span 1;
  }
}
.shopping-form__text {
  max-width: 250px;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.005em;
  color: #919395;
  margin-bottom: 25px;
}
.shopping-form__button {
  margin-top: 20px;
}
@media (max-width: 576px) {
  .shopping-form__button {
    font-size: 18px;
  }
}
.shopping-form__btn {
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0.025em;
  color: #ffffff;
  margin-left: auto;
  padding: 20px 15px;
  margin-bottom: 10px;
}
@media (max-width: 576px) {
  .shopping-form__btn {
    font-size: 18px;
    margin-top: 20px;
  }
}
.shopping-form-terms {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.005em;
  max-width: 530px;
  text-align: right;
  margin-left: auto;
}
.shopping-form-terms a {
  color: #000000;
  text-decoration: underline;
}

.details-card__counter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 576px) {
  .details-card__counter {
    width: 100%;
    margin-bottom: 20px;
  }
}

.details-card__form {
  margin-right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.details-card__btn,
.details-card__input {
  border: 1px solid #919395;
  border-radius: 5px;
  height: 55px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #333;
  font-size: 34px;
  width: 55px;
  cursor: pointer;
  margin-right: 10px;
  font-weight: 700;
}

.details-card__input {
  display: inline-block;
  text-align: center;
}

.details-card__button {
  border: none;
  cursor: pointer;
  background-color: #a3bbc8;
  color: #fff;
  font-size: 15px;
  width: 208px;
  height: 55px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.counter__inner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 992px) {
  .counter__image {
    width: 45%;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
@media (max-width: 576px) {
  .counter__image {
    width: 100%;
  }
}
.counter__details {
  font-size: 25px;
  line-height: 27px;
  letter-spacing: 0.05em;
  color: #000000;
  font-weight: 700;
}
@media (max-width: 992px) {
  .counter__details {
    width: 45%;
  }
}
@media (max-width: 576px) {
  .counter__details {
    width: 100%;
    margin-bottom: 20px;
  }
}
.counter__title {
  font-weight: 300;
}
.counter__text {
  font-weight: 300;
  font-size: 18px;
}
.counter__free {
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.005em;
  color: #383838;
}
.counter__prices {
  display: flex;
  align-items: center;
}
@media (max-width: 576px) {
  .counter__prices {
    width: 100%;
    margin-bottom: 20px;
  }
}
.counter__price-old {
  font-size: 23px;
  font-weight: 300;
  letter-spacing: 0.005em;
  color: #808080;
  padding-right: 15px;
  margin-right: 10px;
  position: relative;
  text-decoration: line-through;
}
.counter__price-old:after {
  content: "/";
  position: absolute;
  right: 0;
}
.counter__price-new {
  font-size: 24px;
  font-weight: 700;
  color: #000000;
}
.counter__close {
  display: block;
  width: 15px;
  height: 15px;
  background-image: url(../img/close.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.counter__sum {
  font-size: 24px;
  font-weight: 700;
  color: #000000;
}
.counter__top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #c4a633;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: #ffffff;
  height: 35px;
  padding-left: 40px;
  padding-top: 8px;
}

.payment {
  margin-bottom: 20px;
}
.payment__title {
  margin-bottom: 10px;
}
.payment__cards {
  width: 544px;
  height: 82px;
  margin-bottom: 30px;
  border: 1px solid #919395;
  border-radius: 20px;
  padding: 10px;
}
.payment__cards img {
  width: 100%;
  object-fit: contain;
}
@media (max-width: 768px) {
  .payment__cards {
    width: 100%;
    height: auto;
  }
}
.payment__table {
  max-width: 540px;
  width: 100%;
}
.payment__table-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.payment__table-name {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.005em;
}
.payment__table-sum {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: 0.005em;
  color: #000000;
}
.payment__table-name--bold {
  font-size: 21px;
  line-height: 26px;
  font-weight: 700;
  letter-spacing: 0.005em;
}
.payment__table-summ--bold {
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0.005em;
  color: #000000;
}
.payment__prom {
  display: flex;
  margin-bottom: 10px;
}
.payment__label {
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.005em;
  margin-right: 10px;
}
.payment__input {
  width: 100px;
  height: 35px;
  border: 1px solid #919395;
  border-radius: 5px;
  margin-left: 10px;
}
.payment__btn {
  background-color: #919395;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: 0.025em;
  color: #ffffff;
  border-radius: 5px;
  padding: 5px 35px;
}
@media (max-width: 768px) {
  .payment__btn {
    padding: 5px 10px;
  }
}
.payment__text {
  font-size: 13px;
  line-height: 26px;
  letter-spacing: 0.005em;
  color: #919395;
}

.update__btn {
  position: relative;
  font-size: 14px;
  line-height: 26px;
  padding-left: 30px;
  display: block;
  margin-bottom: 35px;
}
.update__btn::before {
  content: "";
  position: absolute;
  left: 0;
  width: 23px;
  height: 23px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../img/update.png);
}

.return__btn {
  position: relative;
  font-size: 14px;
  line-height: 26px;
  padding-left: 30px;
}
.return__btn::before {
  content: "";
  position: absolute;
  left: 0;
  width: 23px;
  height: 23px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../img/return.png);
}

.choices__inner {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #919395;
  background-color: #fff;
  min-height: auto;
}

.is-open .choices__inner {
  padding: 5px 10px;
  border-radius: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #000;
  background-color: #fff;
  min-height: auto;
}

.choices__list--dropdown {
  border: 1px solid #000;
  background-color: #fff;
}

.is-open .choices__list--dropdown {
  border: 1px solid #000;
  background-color: #fff;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted:after {
  display: none;
}

.checkbox__input {
  display: none;
}
.checkbox__input:checked + .checkbox__label:after {
  transform: scale(1);
}
.checkbox__label {
  font-size: 16px;
  line-height: 140%;
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  margin-bottom: 10px;
}
.checkbox__label:before {
  content: "";
  align-self: flex-start;
  flex: 0 0 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 4px;
  margin-right: 10px;
}
.checkbox__label::after {
  content: "";
  position: absolute;
  left: 0;
  width: 22px;
  height: 23px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../img/update.png);
  transform: scale(0);
  transition: transform 0.5s ease;
}

.checkbox__label::before {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid #919395;
}

.checkbox._error .checkbox__label::before {
  box-shadow: 0 0 15px red;
}

.shopping {
  padding-bottom: 90px;
}
@media (max-width: 768px) {
  .shopping {
    padding-bottom: 50px;
  }
}
.shopping__title {
  text-align: center;
  font-weight: 300;
  margin-bottom: 55px;
}
.shopping__title--first {
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .shopping__title--first {
    margin-bottom: 20px;
  }
}
.shopping__subtitle {
  font-size: 31px;
  font-weight: 500;
  letter-spacing: 0.05em;
  text-align: center;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .shopping__subtitle {
    font-size: 24px;
  }
}
.shopping__person-text {
  font-size: 19px;
  line-height: 1.2;
  letter-spacing: 0.005em;
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .shopping__person-text {
    font-size: 16px;
  }
}
.shopping__person-text span {
  font-weight: 700;
}
.shopping__person {
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .shopping__person {
    display: block;
  }
}
.shopping__person-img {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  flex-shrink: 0;
}
@media (max-width: 768px) {
  .shopping__person-img {
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}
.shopping__gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
@media (max-width: 992px) {
  .shopping__gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 576px) {
  .shopping__gallery {
    grid-template-columns: repeat(1, 1fr);
  }
}
.shopping__gallery-img {
  border-radius: 20px;
  overflow: hidden;
  min-height: 250px;
  margin-bottom: 25px;
}
.shopping__gallery-img img {
  width: 100%;
  background-color: #919395;
  height: 100%;
  object-fit: cover;
}
.shopping__gallery-text {
  text-align: center;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.05em;
}

.login {
  background-color: #f2f2f2;
  min-height: 100vh;
  padding-bottom: 50px;
}
.login__title {
  font-weight: 300;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 0;
}
.login__subtitle {
  font-size: 13px;
  line-height: 25px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: #383838;
  text-align: center;
  margin-bottom: 70px;
}
.login__link {
  display: block;
  text-align: center;
  font-size: 16px;
  line-height: 25px;
  text-decoration: underline;
  letter-spacing: 0.01em;
  color: #000000;
  text-align: center;
}
.login__link:not(:last-child) {
  margin-bottom: 20px;
}
.login__form {
  max-width: 380px;
  margin: 0 auto;
}
.login__label {
  width: 100%;
  margin-bottom: 15px;
  display: block;
  height: 38px;
}
.login__input {
  width: 100%;
  height: 100%;
  border: 1px solid #919395;
  border-radius: 5px;
  padding: 5px 15px;
}
.login__btn {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.05em;
  width: 100%;
  padding: 5px;
  margin-bottom: 30px;
}

.gift-tabs__inner {
  width: 100%;
}
.gift-tabs__nav {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  row-gap: 10px;
  column-gap: 20px;
}
@media (max-width: 1250px) {
  .gift-tabs__nav {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 850px) {
  .gift-tabs__nav {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
  }
}
@media (max-width: 576px) {
  .gift-tabs__nav {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 400px) {
  .gift-tabs__nav {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5px;
  }
}
.gift-tabs__nav-btn {
  height: 32px;
  width: 180px;
  border: 1px solid #c4a633;
  border-radius: 5px;
  background-color: #fff;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: #383838;
  padding: 5px 10px;
}
@media (max-width: 400px) {
  .gift-tabs__nav-btn {
    width: 140px;
    font-size: 14px;
    padding: 5px;
  }
}

.gift-tabs__title {
  margin-top: 0;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 300;
}
.gift-tabs__subtitle {
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.05em;
  margin-bottom: 20px;
}
.gift-tabs__wrapper {
  width: 100%;
  background-color: #f0f0f0;
  padding: 10px 0;
}
.gift-tabs__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 70px;
  padding-top: 70px;
  padding-bottom: 40px;
}
@media (max-width: 992px) {
  .gift-tabs__container {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 40px;
  }
}
@media (max-width: 576px) {
  .gift-tabs__container {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 20px;
  }
}
.gift-tabs__card-img {
  display: block;
  width: 100%;
  min-height: 250px;
  margin-bottom: 30px;
  border-radius: 20px;
  overflow: hidden;
}
.gift-tabs__card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gift-tabs__card-link {
  display: block;
  text-align: center;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: #000000;
  margin-bottom: 10px;
}
.gift-tabs__card-text {
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.05em;
}

.mixitup-control-active {
  color: #fff;
  background-color: #c4a633;
}

.gift-slider__title {
  text-align: center;
  font-weight: 300;
  margin-bottom: 40px;
}
.gift-slider__descr {
  color: #fff;
  background-color: #c4a633;
  padding: 9px 60px;
  display: inline-block;
  position: absolute;
  top: 25px;
  right: 0;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
  line-height: 100%;
  font-weight: 500;
}
.gift-slider__image {
  display: block;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}
.gift-slider__link {
  text-align: center;
  display: block;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: #000000;
  margin-bottom: 10px;
}
.gift-slider__num {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.05em;
}

.gift-sliderBlock {
  padding: 50px 0;
  background-color: #f0f0f0;
}

.tabs .tabs__nav-btn--active {
  font-weight: 700;
}
.tabs .tabs__panel {
  display: none;
}
.tabs .tabs__panel--active {
  display: block;
}

.single-tabs .tabs {
  padding-bottom: 75px;
}
.single-tabs .tabs__nav {
  display: flex;
}
.single-tabs__btn {
  font-size: 19px;
  line-height: 1.2;
  letter-spacing: 0.05em;
}
@media (max-width: 576px) {
  .single-tabs__btn {
    font-size: 15px;
  }
}
.single-tabs__text {
  font-size: 19px;
  line-height: 1.2;
  letter-spacing: 0.005em;
  margin-bottom: 35px;
}
.single-tabs__text span {
  font-weight: 700;
}
.single-tabs__title {
  font-size: 19px;
  line-height: 1.2;
  letter-spacing: 0.005em;
  font-weight: 700;
  margin-bottom: 10px;
}
.single-tabs__item {
  font-size: 19px;
  line-height: 1.2;
  letter-spacing: 0.05em;
}
.single-tabs__list {
  padding-left: 22px;
}

.tabs__nav-item:not(:last-child) {
  padding-right: 12px;
  margin-right: 12px;
  border-right: 1px solid #000;
}

.tabs__content {
  padding: 20px 0;
}

.single {
  padding: 50px 0 70px;
}
@media (max-width: 768px) {
  .single {
    padding: 10px 0 40px;
  }
}
.single__title {
  font-weight: 300;
  text-align: center;
}
.single__inner {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .single__inner {
    display: block;
  }
}
.single__left {
  min-width: 0;
  max-width: 680px;
}
.single__right {
  flex-shrink: 0;
  width: 280px;
}
.single__right-title {
  font-size: 25px;
  line-height: 29px;
  font-weight: 300;
  letter-spacing: 0.05em;
}
.single__right-subtitle {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.025em;
}
.single__right-name {
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 0.025em;
  margin-bottom: 60px;
}
.single__right-price {
  display: flex;
  margin-bottom: 10px;
}
.single__right-price--old {
  position: relative;
  font-size: 21px;
  font-weight: 400;
  color: #808080;
  padding-right: 13px;
  margin-right: 8px;
}
.single__right-price--old:after {
  content: "/";
  position: absolute;
  right: 0;
}
.single__right-price--new {
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  color: #000000;
}
.single__right-free {
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.005em;
  color: #383838;
  margin-bottom: 40px;
}
.single__btn {
  padding: 20px 55px;
}

.slider-big {
  min-width: 0;
  height: 400px;
  margin-bottom: 15px;
  position: relative;
}
@media (max-width: 768px) {
  .slider-big {
    height: 300px;
  }
}
@media (max-width: 576px) {
  .slider-big {
    height: 200px;
  }
}
.slider-big .swiper-button-prev,
.slider-big .swiper-button-next {
  top: 50%;
  width: 55px;
  height: 55px;
  background-repeat: no-repeat;
  background-size: contain;
}
.slider-big .swiper-button-prev::after,
.slider-big .swiper-button-next:after {
  display: none;
}
.slider-big .swiper-button-next {
  background-image: url(../img/btn-next.png);
}
@media screen and (max-width: 1050px) {
  .slider-big .swiper-button-next {
    right: 10px;
    transform: translateX(0);
  }
}
.slider-big .swiper-button-prev {
  background-image: url(../img/btn-prev.png);
}
.slider-big .gift__image {
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
}
.slider-big .gift__image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.slider-small {
  min-width: 0;
  height: 250px;
}
@media (max-width: 768px) {
  .slider-small {
    height: 200px;
  }
}
@media (max-width: 576px) {
  .slider-small {
    height: 150px;
  }
}
.slider-small .swiper-slide {
  height: 100px;
  margin: 5px 0;
}
@media (max-width: 768px) {
  .slider-small .swiper-slide {
    height: 70px;
  }
}
@media (max-width: 768px) {
  .slider-small .swiper-slide {
    height: 50px;
  }
}
.slider-small .gift__image {
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
}
.slider-small .gift__image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.quiz-top {
  padding-top: 30px;
  padding-bottom: 5px;
  border-bottom: 1px solid #c4a633;
}
.quiz-top__title {
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 48px;
  line-height: 48px;
  font-weight: 300;
  letter-spacing: 0.01em;
  color: #383838;
}
@media (max-width: 576px) {
  .quiz-top__title {
    font-size: 32px;
    line-height: 32px;
  }
}

.quiz-form {
  width: 100%;
}
.quiz .options {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1200px) {
  .quiz .options {
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 1200px) {
  .quiz .choose__left {
    margin-bottom: 30px;
  }
}
.quiz-choose__descr {
  font-size: 20px;
  line-height: 22px;
  font-weight: 300;
  letter-spacing: 0.05em;
  color: #000000;
  padding-left: 35px;
  position: relative;
  display: inline-block;
}
.quiz-choose__descr:before {
  content: "";
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #919395;
  position: absolute;
  top: 0;
  left: 0;
}
.quiz-choose__descr::after {
  content: "";
  display: block;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #919395;
  position: absolute;
  top: 7px;
  left: 7px;
}
.quiz__text {
  font-size: 21px;
  line-height: 25px;
  font-weight: 300;
  letter-spacing: 0.01em;
  color: #000000;
  margin-bottom: 40px;
}
.quiz-form__btn {
  margin: 0 auto;
}

.quiz__step {
  margin: 0 auto;
  padding: 0;
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: #000000;
  position: relative;
  padding-left: 50px;
  display: inline-block;
}
@media (max-width: 576px) {
  .quiz__step {
    text-align: left;
  }
}
.quiz__step:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  color: #fff;
  background-color: #c4a633;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-family: Ubuntu;
  font-size: 21px;
  line-height: 21px;
  font-weight: 700;
  letter-spacing: 0.01em;
}

.quiz1 {
  background-color: #f4f4f4;
  padding: 70px 0 40px;
  text-align: center;
}
@media (max-width: 576px) {
  .quiz1 {
    text-align: left;
  }
}
.quiz1__step::before {
  content: "1";
}
.quiz1 .choose__subtitle {
  font-weight: 300;
}
.quiz1 .choose__left {
  margin-right: 10px;
}
.quiz1-choose__images {
  display: flex;
  max-width: 620px;
  height: 220px;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  border: 2px solid #919395;
}
@media (max-width: 768px) {
  .quiz1-choose__images {
    flex-direction: column;
    height: auto;
  }
}
.quiz1-options__input {
  display: none;
}
.quiz1-options__input:checked + .quiz1-options__label .quiz1-choose__images {
  border: 2px solid #c4a633;
}
.quiz1-options__input:checked + .quiz1-options__label .quiz-choose__descr {
  font-weight: 700;
}
.quiz1-options__input:checked + .quiz1-options__label .quiz-choose__descr::before {
  border: 1px solid #c4a633;
  background-color: #c4a633;
}
.quiz1-options__input:checked + .quiz1-options__label .quiz-choose__descr:after {
  background-color: #fff;
}

.quiz2 {
  background-color: #cccccc;
  padding: 70px 0 40px;
}
.quiz2__container {
  text-align: center;
}
@media (max-width: 576px) {
  .quiz2__container {
    text-align: left;
  }
}
.quiz2__step {
  margin-bottom: 65px;
}
@media (max-width: 576px) {
  .quiz2__step {
    text-align: left;
  }
}
.quiz2__step::before {
  content: "2";
}
.quiz2-options__text {
  font-size: 21px;
  line-height: 37px;
  font-weight: 300;
  letter-spacing: 0.01em;
  color: #000000;
  position: relative;
  padding-left: 35px;
  display: inline-block;
  margin-bottom: 30px;
}
.quiz2-options__text:before {
  content: "";
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #919395;
  position: absolute;
  top: 6px;
  left: 0;
}
.quiz2-options__text::after {
  content: "";
  display: block;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #919395;
  position: absolute;
  top: 13px;
  left: 7px;
}
.quiz2-options {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
}
@media screen and (max-width: 1200px) {
  .quiz2-options {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 768px) {
  .quiz2-options {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 576px) {
  .quiz2-options {
    grid-template-columns: repeat(2, 1fr);
  }
}
.quiz2-options__img {
  display: block;
  width: 180px;
  height: 180px;
  border: 2px solid transparent;
  border-radius: 10px;
  object-fit: contain;
}
@media (max-width: 576px) {
  .quiz2-options__img {
    width: 130px;
    height: 130px;
  }
}
.quiz2-options__input {
  display: none;
}
.quiz2-options__input:checked + .quiz2-options__label .quiz2-options__img {
  border: 2px solid #c4a633;
}
.quiz2-options__input:checked + .quiz2-options__label .quiz2-options__text {
  font-weight: 700;
}
.quiz2-options__input:checked + .quiz2-options__label .quiz2-options__text::before {
  border: 1px solid #c4a633;
  background-color: #c4a633;
}
.quiz2-options__input:checked + .quiz2-options__label .quiz2-options__text:after {
  background-color: #fff;
}

.quiz3 {
  background-color: #e0e0e0;
  padding: 70px 0 140px;
  text-align: center;
}
@media (max-width: 576px) {
  .quiz3 {
    text-align: left;
  }
}
@media (max-width: 768px) {
  .quiz3 {
    padding: 70px 0 40px;
  }
}
@media (max-width: 576px) {
  .quiz3__step {
    text-align: left;
  }
}
.quiz3__step:before {
  content: "3";
}
.quiz3__wrapper {
  overflow: auto;
}
.quiz3__inner {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  -webkit-column-gap: 8px;
  -moz-column-gap: 8px;
  column-gap: 9px;
  row-gap: 1px;
  margin: 0 auto;
  background-image: url(../img/quiz/quiz-bg.png);
  width: 1180px;
  height: 367px;
  padding: 28px 24px;
  margin-bottom: 30px;
}
.quiz3__btn {
  width: 95px;
  height: 95px;
  border: 4px solid transparent;
  border-radius: 10px;
}
.quiz3__btn img {
  width: 100%;
  object-fit: contain;
}
.quiz3__btn:hover {
  border: 4px solid #c4a633;
}

.graph-modal__container.graph-modal__container--quiz {
  padding: 0;
  border-radius: 0;
}

.graph-modal__container .graph-modal__close {
  top: 20px;
  right: 20px;
}

.quiz-modal__top {
  background-color: #f4f4f4;
  padding: 35px;
}
@media (max-width: 576px) {
  .quiz-modal__top {
    padding: 35px 10px;
  }
}
.quiz-modal__top-title {
  font-size: 25px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: #000000;
  text-align: center;
  margin-bottom: 25px;
}
.quiz-modal__content {
  display: flex;
  justify-content: center;
}
@media (max-width: 768px) {
  .quiz-modal__content {
    flex-direction: column;
    align-items: center;
  }
}
.quiz-modal__img {
  display: block;
  width: 240px;
  height: 240px;
  border-radius: 50%;
  margin-right: 20px;
  box-shadow: 5px 7px 10px 1px rgba(25, 25, 25, 0.56);
  object-fit: cover;
}
@media (max-width: 768px) {
  .quiz-modal__img {
    margin-right: 0;
    margin-bottom: 30px;
  }
}
.quiz-modal__input {
  width: 100%;
  height: 42px;
  border: 1px solid #919395;
  border-radius: 5px;
  padding: 10px 20px;
  margin-bottom: 5px;
}
.quiz-modal__input::placeholder {
  font-size: 20px;
  line-height: 37px;
  font-weight: 300;
  color: #808080;
}
.quiz-modal__text {
  text-align: center;
  font-size: 15px;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: #000000;
  font-weight: 400;
  margin-bottom: 35px;
}
.quiz-modal__text span {
  font-weight: 700;
}
.quiz-modal__counter {
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 37px;
  font-weight: 300;
  color: #000000;
  margin-bottom: 40px;
}
.quiz-modal__btn--minus {
  width: 36px;
  height: 36px;
  border: 1px solid #919395;
  border-radius: 3px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  cursor: pointer;
}
.quiz-modal__btn--plus {
  width: 36px;
  height: 36px;
  border: 1px solid #919395;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  cursor: pointer;
}
.quiz-modal__input--counter {
  font-weight: 700;
  border: none;
  background-color: transparent;
  max-width: 55px;
  padding: 10px;
}
.quiz-modal__submit {
  width: 100%;
}
.quiz-modal__bottom {
  background-color: #e0e0e0;
  padding: 35px;
  text-align: center;
}
@media (max-width: 576px) {
  .quiz-modal__bottom {
    padding: 35px 10px;
  }
}
.quiz-modal__bottom-title {
  margin-bottom: 15px;
  font-size: 25px;
  line-height: 37px;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: #000000;
  text-align: center;
}
.quiz-modal__bottom-inner {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .quiz-modal__bottom-inner {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 576px) {
  .quiz-modal__bottom-inner {
    grid-template-columns: repeat(2, 1fr);
  }
}
.quiz-modal__item {
  border-radius: 50%;
  box-shadow: 5px 7px 10px 1px rgba(25, 25, 25, 0.56);
  width: 135px;
  height: 135px;
}
@media (max-width: 576px) {
  .quiz-modal__item {
    width: 120px;
    height: 120px;
  }
}
.quiz-modal__option-img {
  border-radius: 50%;
}
.quiz-modal__option-input {
  display: none;
}
.quiz-modal__option-input:checked + .quiz-modal__option-label .quiz-modal__option-img {
  border: 4px solid #c4a633;
}
.quiz-modal__bottom-btn {
  text-align: center;
  font-size: 20px;
  line-height: 37px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: #000000;
  border-bottom: 1px dashed #000;
}

.modal-form__btn {
  margin-top: 20px;
}

.graph-modal__container.graph-modal__container--first {
  padding: 30px;
}

.graph-modal__container--first .graph-modal__close {
  top: 20px;
  right: 20px;
  opacity: 1;
}

.footer {
  background-color: #d6d6d6;
  padding: 45px 0 100px;
}
@media (max-width: 768px) {
  .footer {
    padding: 45px 0 0 0;
  }
}
.footer__container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .footer__column {
    margin-bottom: 30px;
  }
}
.footer__list {
  display: flex;
  flex-direction: column;
}
.footer__item:not(:last-child) {
  margin-bottom: 30px;
}
.footer__link {
  font-weight: 300;
  letter-spacing: 0.05em;
  color: #080609;
  transition: color 0.3s ease-in-out;
}
.footer__link:hover {
  color: #c4a633;
}
.footer__phone {
  display: block;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: #080609;
  margin-bottom: 20px;
}
.footer__mail {
  font-weight: 300;
  letter-spacing: 0.05em;
  color: #080609;
}
.footer__form-title {
  line-height: 21px;
  font-weight: 300;
  letter-spacing: 0.05em;
  color: #080609;
  max-width: 250px;
  margin-bottom: 35px;
}
.footer__form__label {
  display: block;
  width: 265px;
  height: 40px;
  border: 1px solid #080609;
  border-radius: 5px;
  position: relative;
}
.footer__form__label:after {
  content: "";
  position: absolute;
  top: -8px;
  right: -8px;
  width: 55px;
  height: 55px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../img/form-arr.png);
}
.footer__form-input {
  border: none;
  background-color: transparent;
  width: 100%;
  height: 100%;
  padding: 5px 55px 5px 20px;
}
.footer__column-last {
  padding-left: 15px;
  border-left: 2px solid #080609;
}
@media screen and (max-width: 968px) {
  .footer__column-last {
    width: 100%;
    border: none;
    padding: 0;
    padding-top: 30px;
  }
}
@media (max-width: 768px) {
  .footer__column-social {
    width: 100%;
    padding-top: 30px;
  }
}

.social {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.social__item:not(:last-child) {
  margin-right: 15px;
}

.social__link {
  display: block;
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
  background-size: cover;
}

.social__link--fb {
  background-image: url(../img/fb.png);
}

.social__link--in {
  background-image: url(../img/in.png);
}

.social__link--youtube {
  background-image: url(../img/youtube.png);
}