.grid {
  padding-top: 100px;

  @include small-tablet {
    padding-top: 50px;
  }

  &__title {
    margin-bottom: 20px;
    text-align: center;
    font-weight: 300;

    @include small-tablet {
      font-size: 26px;
    }
  }

  &__subtitle {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.05em;
    margin-bottom: 50px;
    text-align: center;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @media screen and(max-width: 1050px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include small-tablet {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__card-image {
    width: 100%;
    object-fit: contain;
    display: block;
    margin-bottom: 30px;
    height: 250px;
  }

  &__card-title {
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.05em;
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-bottom: 10px;
    color: #000;

  }

  &__card-descr {
    text-align: center;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.05em;
    margin-bottom: 20px;
  }

  &__card-link {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    text-decoration: underline;
    color: $color-gold;
    text-align: center;
    display: block;
    margin: 0 auto;
  }
}