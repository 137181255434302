.tabs {
  @include tabs;
}

.single-tabs {
  .tabs {
    padding-bottom: 75px;
  }

  .tabs__nav {
    display: flex;
  }

  &__btn {
    font-size: 19px;
    line-height: 1.2;
    letter-spacing: 0.05em;

    @include mobile {
      font-size: 15px;
    }
  }

  &__text {
    font-size: 19px;
    line-height: 1.2;
    letter-spacing: 0.005em;
    margin-bottom: 35px;

    span {
      font-weight: 700;
    }
  }

  &__title {
    font-size: 19px;
    line-height: 1.2;
    letter-spacing: 0.005em;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__item {
    font-size: 19px;
    line-height: 1.2;
    letter-spacing: 0.05em;
  }

  &__list {
    padding-left: 22px;
  }
}

.tabs__nav-item:not(:last-child) {
  padding-right: 12px;
  margin-right: 12px;
  border-right: 1px solid #000;
}

.tabs__content {
  padding: 20px 0;
}