.shopping-form {
  padding-top: 15px;
  padding-bottom: 100px;

  &__title {
    text-align: center;
    font-weight: 300;
    margin-bottom: 30px;
  }

  &__item {
    min-height: 220px;
    border: 1px solid $color-grey;
    border-radius: 20px;
    padding: 30px;
    overflow: hidden;
  }

  &__item--counter {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }

  &__subtitle {
    font-size: 43.3px;
    font-weight: 400;
    color: $color-gold;
    margin-bottom: 20px;

    @media (max-width: (768px)) {
      font-size: 26px;
    }
  }

  &__item--sendershort {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  &__item--sender {
    margin-bottom: 20px;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 880px;
    width: 100%;

    @media (max-width: (992px)) {
      display: block;
    }
  }

  &__label {
    font-size: 12px;
    line-height: 26px;
    letter-spacing: 0.005em;
    display: flex;
    flex-direction: column;
    width: 100%;

    span {
      font-size: 13px;
      line-height: 15px;
      letter-spacing: 0.005em;
      color: #919395;
      max-width: 250px;
      margin-top: 10px;
    }
  }

  &__label:not(:last-child) {
    margin-right: 20px;
  }

  &__input {
    height: 43px;
    border: 1px solid $color-grey;
    border-radius: 5px;
    padding: 10px 20px;

    &::placeholder {
      font-size: 12px;
      line-height: 26px;
      letter-spacing: 0.005em;
    }
  }

  &__label--phone {
    margin-top: 40px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @media (max-width: (992px)) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: (768px)) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__label--long {
    grid-column: span 2;

    @media (max-width: (768px)) {
      grid-column: span 1;
    }
  }

  &__text {
    max-width: 250px;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.005em;
    color: #919395;
    margin-bottom: 25px;
  }

  &__button {
    margin-top: 20px;

    @include mobile {
      font-size: 18px;
    }
  }

  &__btn {
    font-size: 21px;
    font-weight: 700;
    letter-spacing: 0.025em;
    color: #ffffff;
    margin-left: auto;
    padding: 20px 15px;
    margin-bottom: 10px;

    @include mobile {
      font-size: 18px;
      margin-top: 20px;
    }
  }

  &-terms {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.005em;
    max-width: 530px;
    text-align: right;
    margin-left: auto;

    a {
      color: #000000;
      text-decoration: underline;
    }
  }
}

.details-card__counter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  @include mobile {
    width: 100%;
    margin-bottom: 20px;
  }
}

.details-card__form {
  margin-right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.details-card__btn,
.details-card__input {
  border: 1px solid $color-grey;
  border-radius: 5px;
  height: 55px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #333;
  font-size: 34px;
  width: 55px;
  cursor: pointer;
  margin-right: 10px;
  font-weight: 700;
}


.details-card__input {
  display: inline-block;
  text-align: center
}

.details-card__button {
  border: none;
  cursor: pointer;
  background-color: #a3bbc8;
  color: #fff;
  font-size: 15px;
  width: 208px;
  height: 55px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.counter {
  &__inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

  }

  &__image {
    @media (max-width: (992px)) {
      width: 45%;
      margin-right: 20px;
      margin-bottom: 20px;
    }

    @include mobile {
      width: 100%;
    }
  }

  &__details {
    font-size: 25px;
    line-height: 27px;
    letter-spacing: 0.05em;
    color: #000000;
    font-weight: 700;

    @media (max-width: (992px)) {
      width: 45%;
    }

    @include mobile {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  &__title {
    font-weight: 300;
  }

  &__subtitle {}

  &__text {
    font-weight: 300;
    font-size: 18px;
  }

  &__free {
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.005em;
    color: #383838;
  }

  &__prices {
    display: flex;
    align-items: center;

    @include mobile {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  &__price-old {
    font-size: 23px;
    font-weight: 300;
    letter-spacing: 0.005em;
    color: #808080;
    padding-right: 15px;
    margin-right: 10px;
    position: relative;
    text-decoration: line-through;

    &:after {
      content: "/";
      position: absolute;
      right: 0;
    }

  }

  &__price-new {
    font-size: 24px;
    font-weight: 700;
    color: #000000;
  }

  &__close {
    display: block;
    width: 15px;
    height: 15px;
    background-image: url(../img/close.png);
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__sum {
    font-size: 24px;
    font-weight: 700;
    color: #000000;
  }

  &__top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: $color-gold;
    font-weight: 700;
    letter-spacing: 0.05em;
    color: #ffffff;
    height: 35px;
    padding-left: 40px;
    padding-top: 8px;
  }
}

.payment {
  margin-bottom: 20px;

  &__title {
    margin-bottom: 10px;
  }

  &__cards {
    width: 544px;
    height: 82px;
    margin-bottom: 30px;
    border: 1px solid $color-grey;
    border-radius: 20px;
    padding: 10px;

    img {
      width: 100%;
      object-fit: contain;
    }

    @include small-tablet {
      width: 100%;
      height: auto;
    }
  }

  &__table {
    max-width: 540px;
    width: 100%;
  }

  &__table-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &__table-name {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.005em;
  }

  &__table-sum {
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    letter-spacing: 0.005em;
    color: #000000;
  }

  &__table-name--bold {
    font-size: 21px;
    line-height: 26px;
    font-weight: 700;
    letter-spacing: 0.005em;
  }

  &__table-summ--bold {
    font-size: 21px;
    font-weight: 700;
    letter-spacing: 0.005em;
    color: #000000;
  }

  &__prom {
    display: flex;
    margin-bottom: 10px;
  }

  &__label {
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.005em;
    margin-right: 10px;
  }

  &__input {
    width: 100px;
    height: 35px;
    border: 1px solid $color-grey;
    border-radius: 5px;
    margin-left: 10px;
  }

  &__btn {
    background-color: $color-grey;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    letter-spacing: 0.025em;
    color: #ffffff;
    border-radius: 5px;
    padding: 5px 35px;

    @include small-tablet {
      padding: 5px 10px;
    }
  }

  &__text {
    font-size: 13px;
    line-height: 26px;
    letter-spacing: 0.005em;
    color: #919395;
  }
}

.update__btn {
  position: relative;
  font-size: 14px;
  line-height: 26px;
  padding-left: 30px;
  display: block;
  margin-bottom: 35px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 23px;
    height: 23px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../img/update.png);
  }
}

.return__btn {
  position: relative;
  font-size: 14px;
  line-height: 26px;
  padding-left: 30px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 23px;
    height: 23px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../img/return.png);
  }
}

.choices__inner {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid $color-grey;
  background-color: #fff;
  min-height: auto;
}

.is-open .choices__inner {
  padding: 5px 10px;
  border-radius: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #000;
  background-color: #fff;
  min-height: auto;
}

.choices__list--dropdown {
  border: 1px solid #000;
  background-color: #fff;
}

.is-open .choices__list--dropdown {
  border: 1px solid #000;
  background-color: #fff;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted:after {
  display: none;
}

.checkbox {
  &__input {
    display: none;

    &:checked+.checkbox__label:after {
      transform: scale(1);
    }
  }

  &__label {
    font-size: 16px;
    line-height: 140%;
    display: inline-flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    margin-bottom: 10px;

    &:before {
      content: "";
      align-self: flex-start;
      flex: 0 0 22px;
      height: 22px;
      background-color: #fff;
      border-radius: 4px;
      margin-right: 10px;
    }


    // &:after {
    //   content: "";
    //   position: absolute;
    //   height: 16px;
    //   width: 16px;
    //   top: 4px;
    //   left: 4px;
    //   background-color: rgb(61, 160, 69);
    //   border-radius: 4px;
    //   transform: scale(0);
    //   transition: transform 0.5s ease;
    // }
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 22px;
      height: 23px;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(../img/update.png);
      transform: scale(0);
      transition: transform 0.5s ease;
    }
  }
}

.checkbox__label::before {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid $color-grey;
}

.checkbox._error {
  .checkbox__label::before {
    box-shadow: 0 0 15px red;
  }
}