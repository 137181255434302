.login {
  background-color: #f2f2f2;
  min-height: 100vh;
  padding-bottom: 50px;

  &__title {
    font-weight: 300;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 0;
  }

  &__subtitle {
    font-size: 13px;
    line-height: 25px;
    font-weight: 400;
    letter-spacing: 0.01em;
    color: #383838;
    text-align: center;
    margin-bottom: 70px;
  }

  &__link {
    display: block;
    text-align: center;
    font-size: 16px;
    line-height: 25px;
    text-decoration: underline;
    letter-spacing: 0.01em;
    color: #000000;
    text-align: center;
  }

  &__link:not(:last-child) {
    margin-bottom: 20px;
  }

  &__form {
    max-width: 380px;
    margin: 0 auto;
  }

  &__label {
    width: 100%;
    margin-bottom: 15px;
    display: block;
    height: 38px;
  }

  &__input {
    width: 100%;
    height: 100%;
    border: 1px solid $color-grey;
    border-radius: 5px;
    padding: 5px 15px;
  }

  &__btn {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.05em;
    width: 100%;
    padding: 5px;
    margin-bottom: 30px;
  }
}