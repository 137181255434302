.single {
  padding: 50px 0 70px;

  @include small-tablet {
    padding: 10px 0 40px;
  }

  &__title {
    font-weight: 300;
    text-align: center;
  }

  &__inner {
    display: flex;
    justify-content: space-between;

    @include tablet {
      display: block;
    }
  }

  &__left {
    min-width: 0;
    max-width: 680px;
  }

  &__right {
    flex-shrink: 0;
    width: 280px;
  }

  &__right-title {
    font-size: 25px;
    line-height: 29px;
    font-weight: 300;
    letter-spacing: 0.05em;
  }

  &__right-subtitle {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.025em;
  }

  &__right-name {
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0.025em;
    margin-bottom: 60px;
  }

  &__right-price {
    display: flex;
    margin-bottom: 10px;
  }

  &__right-price--old {
    position: relative;
    font-size: 21px;
    font-weight: 400;
    color: #808080;
    padding-right: 13px;
    margin-right: 8px;

    &:after {
      content: "/";
      position: absolute;
      right: 0;
    }
  }

  &__right-price--new {
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    color: #000000;
  }

  &__right-free {
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.005em;
    color: #383838;
    margin-bottom: 40px;
  }

  &__btn {
    padding: 20px 55px;
  }
}

.slider-big {
  min-width: 0;
  height: 400px;
  margin-bottom: 15px;
  position: relative;

  @include small-tablet {
    height: 300px;
  }

  @include mobile {
    height: 200px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: 50%;
    width: 55px;
    height: 55px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .swiper-button-prev::after,
  .swiper-button-next:after {
    display: none;
  }

  .swiper-button-next {
    background-image: url(../img/btn-next.png);

    @media screen and(max-width:1050px) {
      right: 10px;
      transform: translateX(0);
    }
  }

  .swiper-button-prev {
    background-image: url(../img/btn-prev.png);

    @media screen and(max-width:1050px) {}
  }

  .gift__image {
    display: block;
    height: 100%;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.slider-small {
  min-width: 0;
  height: 250px;

  @include small-tablet {
    height: 200px;
  }

  @include mobile {
    height: 150px;
  }

  .swiper-slide {
    height: 100px;
    margin: 5px 0;

    @include small-tablet {
      height: 70px;
    }

    @include small-tablet {
      height: 50px;
    }
  }

  // .swiper-slide-active {
  //   border: 2px solid $color-gold;
  // }

  .gift__image {
    display: block;
    height: 100%;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}